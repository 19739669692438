import "./css/OnBoardingFrame.css";
import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    useHistory,
    Link
} from "react-router-dom";
import axios from 'axios';
import Carousel, { CarouselItem } from "../helpers/Carousel.js";

export const OnBoardingFrame = () => {
    let history = useHistory();
    const [state, setstate] = useState({ data: "" })
    const next = async event => {
    }
    const redirect = async event => {
       
        history.push('/StartOfTheGame')

    }
    return (
        <div>
            <Carousel>
                <CarouselItem>  <div className="home-frame11">
                    <span className="home-text" onClick={next }>
                        <span>Пропустить</span>
                    </span>
            {/*        <span className="home-text02">*/}
            {/*            <span>Пока игра не началась, вы можете <br /> ознакомиться с правилами</span>*/}
            {/*        </span>*/}
            {/*        <span className="home-text04">*/}
            {/*            <span>Оставшееся время до начала игры</span>*/}
            {/*        </span>*/}
            {/*        <span className="home-text06">*/}
            {/*            <span>Игра скоро начнется</span>*/}
            {/*        </span>*/}
            {/*        <span className="home-text08">*/}
            {/*            <span>На главном экране</span>*/}
            {/*        </span>*/}
            {/*        <img*/}
            {/*            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/5c03e9f0-66d5-4585-ab10-eb0ee3e76c37?org_if_sml=11750"*/}
            {/*            alt="Rectangle42061764"*/}
            {/*            className="home-rectangle4206"*/}
            {/*        />*/}
            {/*        <img*/}
            {/*            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/daa22569-d002-41a5-828a-ef0e169b35d3?org_if_sml=1478"*/}
            {/*            alt="Rectangle42091765"*/}
            {/*            className="home-rectangle4209"*/}
            {/*        />*/}
            {/*        <img*/}
            {/*            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/0275a881-00bd-4990-a10e-58ec3d6ac416?org_if_sml=1478"*/}
            {/*            alt="Rectangle42101765"*/}
            {/*            className="home-rectangle4210"*/}
            {/*        />*/}
            {/*        <img*/}
            {/*            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/45c37329-02f8-4556-a7ef-21a718fafab5?org_if_sml=1478"*/}
            {/*            alt="Rectangle42111765"*/}
            {/*            className="home-rectangle4211"*/}
            {/*        />*/}
            {/*        <div className="home-frame1948754412">*/}
            {/*            <div className="home-frame1948754411">*/}
            {/*                <div className="home-rectangle421001">*/}
            {/*                    <img*/}
            {/*                        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/2ea64a32-004c-49ef-9a1d-05bcf165ddee?org_if_sml=1283"*/}
            {/*                        alt="Rectangle42101765"*/}
            {/*                        className="home-rectangle421002"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*                <div className="home-rectangle4213">*/}
            {/*                    <img*/}
            {/*                        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/e59c64ad-d2dd-4662-85da-6cb706f5fe8a?org_if_sml=1283"*/}
            {/*                        alt="Rectangle4210I176"*/}
            {/*                        className="home-rectangle421003"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <img*/}
            {/*                src="/playground_assets/vector1765-4xtq.svg"*/}
            {/*                alt="Vector1765"*/}
            {/*                className="home-vector"*/}
            {/*            />*/}
            {/*            <div className="home-frame1948754410">*/}
            {/*                <div className="home-rectangle4214">*/}
            {/*                    <img*/}
            {/*                        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/418353c7-201f-4d3b-bc1f-6c7642465962?org_if_sml=1283"*/}
            {/*                        alt="Rectangle4210I176"*/}
            {/*                        className="home-rectangle421004"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*                <div className="home-rectangle4215">*/}
            {/*                    <img*/}
            {/*                        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/0c382a07-a00a-4fcc-ae6d-9f091ea3f682?org_if_sml=1283"*/}
            {/*                        alt="Rectangle4210I176"*/}
            {/*                        className="home-rectangle421005"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <img src="/playground_assets/vector1765-zy2r.svg" alt="Vector1765" />*/}
            {/*            <div className="home-frame1948754409">*/}
            {/*                <div className="home-rectangle4216">*/}
            {/*                    <img*/}
            {/*                        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/c95ac874-c77c-4925-b6e7-25dd49801798?org_if_sml=1283"*/}
            {/*                        alt="Rectangle4210I176"*/}
            {/*                        className="home-rectangle421006"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*                <div className="home-rectangle4217">*/}
            {/*                    <img*/}
            {/*                        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/76f28402-d185-480e-9ab6-076974efaa2e?org_if_sml=1283"*/}
            {/*                        alt="Rectangle4210I176"*/}
            {/*                        className="home-rectangle421007"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <img*/}
            {/*            src="/playground_assets/frame19487544131765-jss.svg"*/}
            {/*            alt="Frame19487544131765"*/}
            {/*            className="home-frame1948754413"*/}
            {/*        />*/}
            {/*        <img*/}
            {/*            src="/playground_assets/frame19487544141765-hia6.svg"*/}
            {/*            alt="Frame19487544141765"*/}
            {/*            className="home-frame1948754414"*/}
            {/*        />*/}
            {/*        <span className="home-text10">*/}
            {/*            <span>Правила</span>*/}
            {/*        </span>*/}
            {/*        <span className="home-text12">*/}
            {/*            <span>Таймер</span>*/}
            {/*        </span>*/}
            {/*        <div className="home-frame1948754416">*/}
            {/*            <img*/}
            {/*                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/ad47fa81-ef15-4e15-b94e-38359adc5349?org_if_sml=1189"*/}
            {/*                alt="Ellipse2821767"*/}
            {/*                className="home-ellipse282"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/51797b18-97ed-44ac-9610-d37684e0ba49?org_if_sml=1215"*/}
            {/*                alt="Ellipse2831767"*/}
            {/*                className="home-ellipse283"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/29d77c44-d1a9-44c0-bddf-905b1d329d3b?org_if_sml=1215"*/}
            {/*                alt="Ellipse2841767"*/}
            {/*                className="home-ellipse284"*/}
            {/*            />*/}
                    {/*        </div>*/}
                    <img
                        className="group1"
                        src="../GroupOnbording1.svg" />
                </div>
                    
            </CarouselItem>
                <CarouselItem><div className="home-frame12">
                    <span className="home-text27">
                        <span>Пропустить</span>
                    </span>
                    <img
                        className="group2"
                        src="../GroupOnbording2.svg" />
            {/*        <span className="home-text14">*/}
            {/*            <span>*/}
            {/*                Выберите один из вариантов. Если <br />передумали, ответ можно изменить,<br />*/}
            {/*                пока не закончилось время.*/}
            {/*            </span>*/}
            {/*        </span>*/}
            {/*        <span className="home-text16">*/}
            {/*            <span>Оставшееся время для ответа</span>*/}
            {/*        </span>*/}
            {/*        <span className="home-text18">*/}
            {/*            <span>*/}
            {/*                Игра состоит из 10 вопросов. На<br /> каждый вопрос дается 10 секунд*/}
            {/*            </span>*/}
            {/*        </span>*/}
            {/*        <span className="home-text20">*/}
            {/*            <span>В игре</span>*/}
            {/*        </span>*/}
            {/*        <img*/}
            {/*            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/b7e4c595-3f3e-4554-a3d2-02097142e547?org_if_sml=11750"*/}
            {/*            alt="Rectangle42061766"*/}
            {/*            className="home-rectangle42061"*/}
            {/*        />*/}
            {/*        <img*/}
            {/*            src="/playground_assets/frame19487544131766-nd6.svg"*/}
            {/*            alt="Frame19487544131766"*/}
            {/*            className="home-frame19487544131"*/}
            {/*        />*/}
            {/*        <div className="home-frame1948754415">*/}
            {/*            <img*/}
            {/*                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/e8e38cd1-5972-446f-b6ef-242db465b0cc?org_if_sml=1123"*/}
            {/*                alt="Rectangle42091766"*/}
            {/*                className="home-rectangle42091"*/}
            {/*            />*/}
            {/*            <div className="home-group1948754306">*/}
            {/*                <img*/}
            {/*                    src="/playground_assets/subtract1833-14uf.svg"*/}
            {/*                    alt="Subtract1833"*/}
            {/*                    className="home-subtract"*/}
            {/*                />*/}
            {/*                <img*/}
            {/*                    src="/playground_assets/vector11833-e7pm.svg"*/}
            {/*                    alt="Vector11833"*/}
            {/*                    className="home-vector1 home-vector1"*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <img*/}
            {/*                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/b45391d7-ede1-4863-8845-0e8c716109d4?org_if_sml=1123"*/}
            {/*                alt="Rectangle42111766"*/}
            {/*                className="home-rectangle42111"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/8aa15459-2172-466e-ab0e-1b817c367f46?org_if_sml=1123"*/}
            {/*                alt="Rectangle42121766"*/}
            {/*                className="home-rectangle4212"*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <div className="home-frame19487544121">*/}
            {/*            <div className="home-frame19487544111">*/}
            {/*                <div className="home-rectangle421008">*/}
            {/*                    <img*/}
            {/*                        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/f0044eba-a087-444c-a394-d801668c01e4?org_if_sml=1283"*/}
            {/*                        alt="Rectangle4210I176"*/}
            {/*                        className="home-rectangle421009"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <img*/}
            {/*                src="/playground_assets/vector1766-k2ey.svg"*/}
            {/*                alt="Vector1766"*/}
            {/*                className="home-vector2"*/}
            {/*            />*/}
            {/*            <div className="home-frame19487544101">*/}
            {/*                <div className="home-rectangle42141">*/}
            {/*                    <img*/}
            {/*                        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/12e879ff-e7df-4086-b07b-529ebcfcfba9?org_if_sml=1283"*/}
            {/*                        alt="Rectangle4210I176"*/}
            {/*                        className="home-rectangle421010"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*                <div className="home-rectangle42151">*/}
            {/*                    <img*/}
            {/*                        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/e1fb0224-dc20-4b1b-a170-9bb1784cef5b?org_if_sml=1283"*/}
            {/*                        alt="Rectangle4210I176"*/}
            {/*                        className="home-rectangle421011"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <img*/}
            {/*            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/07f9aa1c-7abc-4ec5-9b2b-ae9f5b779593?org_if_sml=1210"*/}
            {/*            alt="Rectangle42081766"*/}
            {/*            className="home-rectangle4208"*/}
            {/*        />*/}
            {/*        <img*/}
            {/*            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/aaeb450a-dff3-4fc5-a718-fb90fcb03947?org_if_sml=1212"*/}
            {/*            alt="Rectangle42091766"*/}
            {/*            className="home-rectangle42092"*/}
            {/*        />*/}
            {/*        <img*/}
            {/*            src="/playground_assets/frame19487544141766-boaq.svg"*/}
            {/*            alt="Frame19487544141766"*/}
            {/*            className="home-frame19487544141"*/}
            {/*        />*/}
            {/*        <span className="home-text22">*/}
            {/*            <span>Вопрос</span>*/}
            {/*        </span>*/}
            {/*        <span className="home-text24">*/}
            {/*            <span>Варианты ответа</span>*/}
            {/*        </span>*/}
            {/*        <span className="home-text26">*/}
            {/*            <span>Таймер</span>*/}
            {/*        </span>*/}
            {/*        <div className="home-frame19487544161">*/}
            {/*            <img*/}
            {/*                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/f8934e8a-99f5-46f6-a04e-f71d46edf0a8?org_if_sml=1215"*/}
            {/*                alt="Ellipse2831767"*/}
            {/*                className="home-ellipse2831"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/4d2720d7-dc45-481f-aa2b-73f5598cdeb3?org_if_sml=1189"*/}
            {/*                alt="Ellipse2821767"*/}
            {/*                className="home-ellipse2821"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*                src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/d17670f0-844a-44ae-aa6d-375e63628081?org_if_sml=1215"*/}
            {/*                alt="Ellipse2841767"*/}
            {/*                className="home-ellipse2841"*/}
            {/*            />*/}
                    {/*        </div>*/}
                   
                </div>
                </CarouselItem>
                <CarouselItem>
                    <div className="home-frame13" >
                        <span className="home-text27" onClick={redirect}>
                            <span>Пропустить</span>
                        </span>
                        <img
                            className="group3"
                            src="../GroupOnbording3.svg" />
                    {/*<span className="home-text28">*/}
                    {/*    <span>*/}
                    {/*        Когда время закончится, вы увидите<br /> правильный ответ и перейдете к<br />*/}
                    {/*        следующему вопросу*/}
                    {/*    </span>*/}
                    {/*</span>*/}
                    {/*<span className="home-text30">*/}
                    {/*    <span>В игре</span>*/}
                    {/*</span>*/}
                    {/*<img*/}
                    {/*    src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/2001177f-aff0-460c-9dc5-ae3fa9c70ec4?org_if_sml=11750"*/}
                    {/*    alt="Rectangle42061767"*/}
                    {/*    className="home-rectangle42062"*/}
                    {/*/>*/}
                    {/*<div className="home-group1948754301">*/}
                    {/*    <div className="home-frame">*/}
                    {/*        <img*/}
                    {/*            src="/playground_assets/vector1767-nxr8.svg"*/}
                    {/*            alt="Vector1767"*/}
                    {/*            className="home-vector3"*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="home-frame19487544162">*/}
                    {/*    <img*/}
                    {/*        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/e7dbd631-5367-4032-a975-ea522846e314?org_if_sml=1215"*/}
                    {/*        alt="Ellipse2831767"*/}
                    {/*        className="home-ellipse2832"*/}
                    {/*    />*/}
                    {/*    <img*/}
                    {/*        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/1ad69f07-3349-48f7-a6c3-91a5ef739a35?org_if_sml=1215"*/}
                    {/*        alt="Ellipse2841767"*/}
                    {/*        className="home-ellipse2842"*/}
                    {/*    />*/}
                    {/*    <img*/}
                    {/*        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/04c81bcd-df6b-4124-87b2-ceb717c60b15/d99b2b13-fae3-4979-8a93-3c5952fc7b91?org_if_sml=1189"*/}
                    {/*        alt="Ellipse2821767"*/}
                    {/*        className="home-ellipse2822"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<img*/}
                    {/*    src="/playground_assets/polygon31793-qg2p.svg"*/}
                    {/*    alt="Polygon31793"*/}
                    {/*    className="home-polygon3"*/}
                    {/*/>*/}
                    {/*<img*/}
                    {/*    src="/playground_assets/polygon41793-5l4c.svg"*/}
                    {/*    alt="Polygon41793"*/}
                    {/*    className="home-polygon4"*/}
                    {/*/>*/}
                </div>
            </CarouselItem>
            </Carousel>
        </div>
    );
};
