import "./css/StartOfTheGameFrame.css";
import fileDownload from 'js-file-download';
import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    useHistory,
    Link
} from "react-router-dom";
import axios from 'axios';
import useWindowDimensions from '../hooks/useWindowDimensions';
export const StartOfTheGameFrame = () => {
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    var deadline = "november, 15, 2022, 15:00"
    useEffect(() => {
        localStorage.setItem('PresentTime', new Date())
        const fetchData = async () => {
            console.log("aaaaa")
            let tmp = await axios.get('https://demo.games.winsolutions.ru/GetPresentTime')
            let gDate = Date.parse(tmp.data)
            console.log(gDate)
            localStorage.setItem('PresentTime', gDate)

        };
        fetchData();
        setInterval(() => getTimeUntil(deadline), 1000);
        return () => getTimeUntil(deadline);
    }, [deadline]);
    useEffect(() => {


    }, [])
    const { height, width } = useWindowDimensions();
    let presentTime = localStorage.getItem('PresentTime')
    if (presentTime == 0) {
        presentTime = new Date()
    }
    const time = Date.parse(deadline) - presentTime;
    const [days, setDays] = useState(Math.floor(time / (1000 * 60 * 60 * 24)));
    const [hours, setHours] = useState(Math.floor((time / (1000 * 60 * 60)) % 24));
    const [minutes, setMinutes] = useState(Math.floor((time / 1000 / 60) % 60));
    const [seconds, setSeconds] = useState(Math.floor((time / 1000) % 60));
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id
    let history = useHistory();
    const leading0 = (num) => {
        return num < 10 ? "0" + num : num;
    };
    const dis = async event => {
        var option1 = document.getElementsByClassName('frame-div3_1');
        option1[0].style.visibility = 'visible'
    }
    const handleClick = async event => {
        let url = 'https://fileshare.drawbot.winsolutions.ru/download/dev/statout/default/rules.pdf'
        let filename = 'Правила.pdf'
        axios.post('https://api.telegram.org/bot1516450805:AAHiZv0tcRPry302BblVx3pTu3GQOSkirtg/sendDocument', { chat_id: userId, document: 'https://fileshare.drawbot.winsolutions.ru/download/dev/statout/default/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%B8%D0%B3%D1%80%D1%8B.pdf' })
        await delay(1000);
        window.Telegram.WebApp.close();
    }
    const getTimeUntil = (deadline) => {
        let tit = localStorage.getItem('PresentTime')
        if (tit == null) {
            tit = presentTime
            console.log(presentTime + " ВРЕМЯ")
        }
        console.log("В функции " + tit)
        const time = Date.parse(deadline) - tit;
        if (time < 0 && (window.location.pathname == "/StartOfTheGame" || window.location.pathname == "/Onboarding")) {
            history.push('/Waiting')
            setDays(0);
            setHours(0);
            setMinutes(0);
            setSeconds(0);
        } else {
            setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
            setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
            setMinutes(Math.floor((time / 1000 / 60) % 60));
            setSeconds(Math.floor((time / 1000) % 60));
            let newTime = parseInt(tit) + 1000
            localStorage.setItem('PresentTime', newTime)
        }
    };


    const redirect = async event => {
        history.push('/Onboarding')

    }
    return (
        <div className="div_1">

            <img className="frame-icon_1" alt="" src="../frame.svg" />
            <div style={{ width: 100 }} onClick={redirect}>
                <img className="help-circle-icon_1" alt="" src="../helpcircle.svg" />
            </div>
            <b className="b_1" onClick={redirect}>Игра начнется через</b>
            <div className="div1_1">Правила игры</div>
            <div className="frame-div_1">
                <div className="div2_1">
                    <p className="p_1">
                        <span className="span_1">1. КАК ИГРАТЬ?</span>
                    </p>
                    <ul className="ul_1">
                        <li className="li_1">
                            <span>Игра состоит из 10 вопросов.</span>
                        </li>
                        <li className="li_1">
                            <span>Для ответа на каждый вопрос у вас есть 15 секунд.</span>
                        </li>
                        <li>
                            <span>Вы можете изменить ответ, пока время не истекло</span>
                        </li>
                    </ul>
                </div>
                <div className="div3_1">
                    <p className="p_1">
                        <span className="span_1">2. КОГДА ИГРАТЬ?</span>
                    </p>
                    <ul className="ul_1">
                        <li>
                            <span>
                                Викторина начнётся 14 сентября ровно в 15:00 по московскому
                                времени
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="div2_1">
                    <p className="p_1">
                        <span className="span_1">3. КАКИЕ ПРИЗЫ МОЖНО ВЫИГРАТЬ?</span>
                    </p>
                    <ul className="ul_1">
                        <li className="li_1">
                            <span>Побеждают все 😉</span>
                        </li>
                        <li className="li_1">
                            <span>500 бонусов на карту Магнит</span>
                        </li>
                        <li className="li_1">
                            <span>300 бонусов на карту Магнит</span>
                        </li>
                        <li>
                            <span>
                                Промокод на 500 рублей на заказ в приложении Магнит Доставка
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="frame-div1_1" onClick={handleClick}>
                    <b className="b1_1">Полные правила</b>
                    <img className="group-icon_1" alt="" src="../group-1948754308.svg" />

                </div>
                <div style={{ position: "absolute", left: width / 2, marginTop: 0.6 * height, fontFamily: 'Montserrat', color: 'white', fontSize: 12 }}><a href="https://www.magnit.ru/">Реклама www.magnit.ru</a></div>
            </div>
            <div className="frame-div2_1">
                <div className="frame-div3_1">
                    <b className="b2_1">{days}</b>
                    <b className="b3_1">Дней</b>
                </div>
                <div className="frame-div3_1">
                    <b className="b2_1">:</b>
                    <b className="b3_1"><br /></b>
                </div>
                <div className="frame-div3_1">
                    <b className="b2_1">{hours}</b>
                    <b className="b3_1">Часов</b>
                </div>
                <div className="frame-div3_1">
                    <b className="b2_1">:</b>
                    <b className="b3_1"><br /></b>
                </div>
                <div className="frame-div3_1">
                    <b className="b2_1">{minutes}</b>
                    <b className="b3_1">Минут</b>
                </div>
                {
                    minutes <= 0 &&
                    <React.Fragment>
                        <div className="frame-div3_1">
                            <b className="b2_1">:</b>
                            <b className="b3_1"><br /></b>
                        </div>
                        <div className="frame-div3_1">{minutes >= '0' ? handleClick : null}
                            <b className="b2_1">{seconds >= '10' ? <span>{seconds}</span> : <span>0{seconds}</span>}</b>
                            <b className="b3_1">Секунд</b>{minutes >= '0' ? handleClick : null}
                        </div>
                    </React.Fragment>
                }
            </div>

            <img className="group-icon1_1" alt="" src="../group-1948754307.svg" />

        </div>
    );
};
