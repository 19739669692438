import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import WrongAnswerFrame from './components/WrongAnswerFrame'
import RightAnswerFrame from './components/RightAnswerFrame'
import { StartOfTheGameFrame } from './components/StartOfTheGameFrame'
import EndOfTheGame from './components/EndOfTheGame'
import { WaitingFrame } from './components/WaitingFrame'
import { OnBoardingFrame } from './components/OnBoardingFrame'
import IPhoneSE1 from './i-phone-s-e1'
import './style.css'
import Home from './views/home'
import Terms from './components/Terms'

const App = () => {
    return (
        <BrowserRouter>
            <div>
                <Route exact component={Home} path="/" />
            </div>
            <Route path="/WrongAnswer">
                <WrongAnswerFrame />
            </Route>
            <Route path="/RightAnswer">
                <RightAnswerFrame />
            </Route>
            <Route path="/EndOfTheGame">
                <EndOfTheGame />
            </Route>
            <Route path="/StartOfTheGame">
                <StartOfTheGameFrame />
            </Route>
            <Route path="/Waiting">
                <WaitingFrame />
            </Route>
            <Route path="/Onboarding">
                <OnBoardingFrame />
            </Route>
            <Route path="/Menu">
                <IPhoneSE1 />
            </Route>
            <Route path="/Terms">
                <Terms />
            </Route>
        </BrowserRouter>
    )
}

ReactDOM.render(<App />, document.getElementById('app'))
