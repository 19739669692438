import "./css/RightAnswerFrame.css";
import React from 'react';
import { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    useHistory,
    Link,
    Redirect,
    withRouter
} from "react-router-dom";
class RightAnswerFrame extends React.Component {
    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
        var cn = parseInt(localStorage.getItem('correctAnswers'))
        console.log(cn)
        if (cn > 0) {

        }
        else { cn = 0 }
        localStorage.setItem('correctAnswers',cn+1)
        var qn = parseInt(localStorage.getItem('End'))
        if (qn == 'true') {
            setTimeout(function () {
                this.props.history.push('/EndOfTheGame')
            }.bind(this), 5000)
        }
        else {
            setTimeout(function () {
                this.props.history.push("/");
            }.bind(this), 5000)
        }
    }
     
    render() {
      return (
            <div className="div10">
                <img className="frame-icon5" alt="" src="frame5.svg" />
                <div className="div11">
                    <span className="txt-span2">
                      <p className="p4" style={{ whiteSpace:'pre' }}>Поздравляем,<br />
                          это правильный</p>
                        <p className="p5">ответ</p>
                    </span>
                </div>
                <img className="group-icon1" alt="" src="ComponentOK.svg" />
                {/*<img className="group-icon2" alt="" src="group-1948754303.svg" />*/}
                <div className="div12">Так держать!</div>
                <img className="group-icon3" alt="" src="group-1948754305.svg" />
                <img className="vector-icon10" alt="" src="vector9.svg" />
            </div>
    );
}
};
export default withRouter(RightAnswerFrame);
