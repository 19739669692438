import React from 'react'

import { Helmet } from 'react-helmet'

import Frame5 from '../components/frame5'
import './home.css'
<meta name="http-equiv" content="Content-type: text/html; charset=UTF-8"/>
const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>�����������</title>
        <meta property="og:title" content="�����������" />
      </Helmet>
          <Frame5></Frame5>
    </div>
  )
}

export default Home
