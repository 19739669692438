import React from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet'
import useWindowDimensions from './hooks/useWindowDimensions';
import useBackgroundHeight from './hooks/useBackgroundHeight';
import './i-phone-s-e1.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    useHistory,
    Link
} from "react-router-dom";
const IPhoneSE1 = (props) => {
    const history = useHistory();
    const { height, width } = useWindowDimensions();
    const { backHeight } = useBackgroundHeight(height);
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const queryId = window.Telegram.WebApp.initDataUnsafe.query_id
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id
    window.Telegram.WebApp.expand();
    delay(1000);
    const heightteleg = window.Telegram.WebApp.viewportStableHeight
    const handleClick = async event => {
        // 👇️ refers to the div element
        
        console.log(event.currentTarget.className);
        switch (event.currentTarget.className) {
            case "i-phone-s-e1-botbtn":
                localStorage.clear();
                history.push("/");
                console.log('div1 clicked');
                break;
            case "i-phone-s-e1-botbtn1":
                console.log('t');
               history.push("/terms")
                break;
            case "i-phone-s-e1-botbtn3":
                axios.post('https://service.mgtprod1.winsolutions.ru/api/Members/ExecuteCommand?command=/catalog&query_id='+queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                    axios.post('https://service.mgtprod2.winsolutions.ru/api/Members/ExecuteCommand?command=/catalog&query_id='+queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                 await delay(1000);
                    window.Telegram.WebApp.close();
                console.log('div1 clicked');
                break;
            case "i-phone-s-e1-botbtn4":
                axios.post('https://service.mgtprod1.winsolutions.ru/api/Members/ExecuteCommand?command=/coupons&query_id=' + queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                                   axios.post('https://service.mgtprod2.winsolutions.ru/api/Members/ExecuteCommand?command=/coupons&query_id=' + queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                 await delay(1000);
window.Telegram.WebApp.close();
                console.log('div2 clicked');
                break;
            case "i-phone-s-e1-botbtn5":
                axios.post('https://service.mgtprod1.winsolutions.ru/api/Members/ExecuteCommand?command=/help&query_id=' + queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                    axios.post('https://service.mgtprod2.winsolutions.ru/api/Members/ExecuteCommand?command=/help&query_id=' + queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                 await delay(1000);
                window.Telegram.WebApp.close();
                console.log('div3 clicked');
                break;
            case "i-phone-s-e1-botbtn6":
                axios.post('https://service.mgtprod1.winsolutions.ru/api/Members/ExecuteCommand?command=/sharebot&query_id=' + queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                    axios.post('https://service.mgtprod2.winsolutions.ru/api/Members/ExecuteCommand?command=/sharebot&query_id=' + queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                await delay(1000);
                window.Telegram.WebApp.close();
                console.log('div4 clicked');
                break;
            case "i-phone-s-e1-botbtn2":
                axios.post('https://service.mgtprod1.winsolutions.ru/api/Members/ExecuteCommand?command=/cabinet&query_id=' + queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                     axios.post('https://service.mgtprod2.winsolutions.ru/api/Members/ExecuteCommand?command=/cabinet&query_id=' + queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                 await delay(1000);
                window.Telegram.WebApp.close();
                console.log('div5 clicked');
                break;
            case "i-phone-s-e1-botbtn7":
                axios.post('https://service.mgtprod1.winsolutions.ru/api/Members/ExecuteCommand?command=/start&query_id=' + queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                    axios.post('https://service.mgtprod2.winsolutions.ru/api/Members/ExecuteCommand?command=/start&query_id=' + queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                console.log('div6 clicked');
                 await delay(1000);
                window.Telegram.WebApp.close();
                break;
            case "i-phone-s-e1-botbtn8":
                axios.post('https://service.mgtprod1.winsolutions.ru/api/Members/ExecuteCommand?command=/sreceipt&query_id=' + queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                axios.post('https://service.mgtprod2.winsolutions.ru/api/Members/ExecuteCommand?command=/sreceipt&query_id=' + queryId)
                    .then(response => {
                        console.log('успешно')
                    })
                    .catch(error => {
                        console.log('ошибка')
                    })
                console.log('div6 clicked');
                await delay(1000);
                window.Telegram.WebApp.close();
                break;
        }
    };

    return (
        <div className="i-phone-s-e1-container">
            <Helmet>
                <title>menu</title>
            </Helmet>
            <div className="i-phone-s-e1-i-phone-s-e1" style={{ paddingLeft: ((width / 2) - (width * 0.4)) }}>
                <div className="i-phone-s-e1-frame1948754382">
                    <div className="i-phone-s-e1-frame1948754376">
                        <img
                            src="/playground_assets/bf2d68be-acb3-4b70-a647-e6cc55244095-83cn.svg"
                            alt="Group4789520534747"
                            className="i-phone-s-e1-group47895"
                        />
                    </div>
                    <div className="i-phone-s-e1-frame1948754380" style={{ height: backHeight, width: width * 0.8 }}>
                        <div className="i-phone-s-e1-frame1948754399" style={{ marginBottom: 10 }}>
                            <div className="i-phone-s-e1-botbtn" onClick={handleClick} style={{ width: (width * 0.8) - 20, marginTop: (height * 0.65 * 0.008), height: (height * 0.69 * 0.12), cursor: 'pointer' }}>
                                <img
                                    src="/playground_assets/7ea7a1db-a337-4adb-9cd7-01fc3227e6a8-t22.svg"
                                    alt="helpcircleI2027496320454416"
                                    className="i-phone-s-e1-helpcircle"
                                />
                                <span className="i-phone-s-e1-text">ВИКТОРИНА</span>
                            </div>



                            <div className="i-phone-s-e1-botbtn1" onClick={handleClick} style={{ width: (width * 0.8) - 20, marginTop: (height * 0.65 * 0.0008), height: (height * 0.69 * 0.12), cursor: 'pointer' }}>
                                 <img
                                        src="/playground_assets/d130c845-4eef-4afc-a689-00d65eaf469d-rvkql.svg"
                                        alt="helpcircleI2299440920454416"
                                        className="i-phone-s-e1-helpcircle1"
                                    />
                                   <span className="i-phone-s-e1-text1" >УСЛОВИЯ АКЦИИ</span>
</div>
                            <div className="i-phone-s-e1-botbtn2" onClick={handleClick} style={{ width: (width * 0.8) - 20, marginTop: (height * 0.65 * 0.0008), height: (height * 0.69 * 0.12), cursor: 'pointer' }}>
                                <img
                                    src="/playground_assets/74cd7edb-427f-48f3-a852-215c14e55803-fe9n.svg"
                                    alt="helpcircleI2027498620454416"
                                    className="i-phone-s-e1-helpcircle2"
                                />
                                <span className="i-phone-s-e1-text2">ЛИЧНЫЙ КАБИНЕТ</span>
                            </div>

                        </div>


                        <div className="i-phone-s-e1-frame1948754400">
                            <div className="i-phone-s-e1-botbtn8" onClick={handleClick} style={{ width: (width * 0.8) - 20, marginTop: (height * 0.65 * 0.0018), height: (height * 0.69 * 0.12), cursor: 'pointer' }}>
                                <img
                                    src="/playground_assets/631e1264-b049-43c2-89f6-a9eed62e8bd2-bpbr.svg"
                                    alt="helpcircleI2027497820494601"
                                    className="i-phone-s-e1-helpcircle8"
                                />
                                <span className="i-phone-s-e1-text8">РЕЦЕПТЫ БЛЮД</span>
                            </div>
                            <div className="i-phone-s-e1-botbtn3" onClick={handleClick} style={{ width: (width * 0.8) - 20, marginTop: (height * 0.65 * 0.028), height: (height * 0.69 * 0.12), cursor: 'pointer' }}>
                                <img
                                    src="/playground_assets/c9f73ebb-2044-43a4-916b-269bb98b5d71-4lpf.svg"
                                    alt="helpcircleI2027497020494601"
                                    className="i-phone-s-e1-helpcircle3"
                                />
                                <span className="i-phone-s-e1-text3">КАТАЛОГ СКИДОК</span>
                            </div>

                            <div className="i-phone-s-e1-botbtn4" onClick={handleClick} style={{ width: (width * 0.8) - 20, marginTop: (height * 0.65 * 0.0008), height: (height * 0.69 * 0.12), cursor: 'pointer' }}>
                                <img
                                    src="/playground_assets/631e1264-b049-43c2-89f6-a9eed62e8bd2-bpbr.svg"
                                    alt="helpcircleI2027497420494601"
                                    className="i-phone-s-e1-helpcircle4"
                                />
                                <span className="i-phone-s-e1-text4">КУПОНЫ</span>
                            </div>
                            <div className="i-phone-s-e1-botbtn5" onClick={handleClick} style={{ width: (width * 0.8) - 20, marginTop: (height * 0.65 * 0.0008), height: (height * 0.69 * 0.12), cursor: 'pointer' }}>
                                <img
                                    src="/playground_assets/cb2d9a40-d75c-40d4-8eec-04374c1a1525-rjbt.svg"
                                    alt="helpcircleI2027497820494601"
                                    className="i-phone-s-e1-helpcircle5"
                                />
                                <span className="i-phone-s-e1-text5">ПОМОЩЬ</span>
                            </div>
                            <div className="i-phone-s-e1-botbtn6" onClick={handleClick} style={{ width: (width * 0.8) - 20, marginTop: (height * 0.65 * 0.0008), height: (height * 0.69 * 0.12), cursor: 'pointer' }}>
                                <img
                                    src="/playground_assets/c1d7ceb5-659a-45d4-95e4-fafbafa49d47-uyk.svg"
                                    alt="helpcircleI2027498220494601"
                                    className="i-phone-s-e1-helpcircle6"
                                />
                                <span className="i-phone-s-e1-text6">ПОДЕЛИТЬСЯ БОТОМ</span>
                            </div>
                            <div className="i-phone-s-e1-botbtn7" onClick={handleClick} style={{ width: (width * 0.8) - 20, marginTop: (height * 0.65 * 0.0008), height: (height * 0.69 * 0.12), cursor: 'pointer' }}>
                                <img
                                    src="/playground_assets/8d789489-c6cf-4073-ba79-c4f21c136486-w70o.svg"
                                    alt="helpcircleI2027499020494601"
                                    className="i-phone-s-e1-helpcircle7"
                                />
                                <span className="i-phone-s-e1-text7">ПЕРЕЗАПУСТИТЬ БОТА</span>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    )
}
function Promoinf() {
    const { height, width } = useWindowDimensions();
    const handleClick = async event => {
        // 👇️ refers to the div element

        const ttt = document.getElementsByClassName('section wf-section')
        console.log(ttt)
        ttt[0].style.display = 'none'
    }
    return (
        <div className="section wf-section" style={{ maxHeight: height, overflow: 'auto', display: 'visible', width: width, top: 0, left: 0, position: 'fixed', zIndex: 1 }}>

            <div id="sponsors" style={{}}><div className="arrow_back_to_menu"><a style={{ color: 'white' }} onClick={handleClick}>
                &#8592;</a></div></div>
            <div className="container-main w-container" style={{}}>

                <div className="white-card" >
                    <div className="card_header">
                        <h2 className="heading_v2">Условия акции</h2>
                    </div>
                    <div className="card_body_v2">
                        <p className="discount_text_v2">До 6 сентября включительно покупайте:</p>
                    </div>

                    <div className="checkpoint__container">

                        <div className="display-flex ">
                            <img src="images/circle.svg" className="violet-circle" />
                            <div className="checkpoint__text">
                                <div>На сумму от 500 ₽ = 1 попытка</div>
                                <div className="fm-Regular">в магазинах Магнит Косметик, Магнит у Дома, Магнит Аптека
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="checkpoint__container">

                        <div className="display-flex">
                            <img src="images/circle.svg" className="violet-circle" />
                            <div className="checkpoint__text">
                                <div>На сумму от 1000 ₽ = 1 попытка</div>
                                <div className="fm-Regular">в магазинах Магнит Семейный, Магнит Экстра, Магнит Опт
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="checkpoint__container">

                        <div className="display-flex">
                            <img src="images/circle.svg" className="violet-circle" />
                            <div className="checkpoint__text">
                                <div>Товары-спонсоры = 1 попытка</div>
                            </div>
                        </div>
                    </div>

                    <div className="inform__block_text_v2">В сумму не входят алкоголь, табачная и никотиносодержащая продукция, а
                        также устройства для нее
                    </div>

                    <div className="inform__block_text_v2">
                        В сутки можно совершить не более двух покупок из одной торговой точки. Третий чек из той же торговой
                        точки, отправленный с того же номера телефона, не будет учтен.
                    </div>

                    <div className="check__container">
                        <img className="check__img" src="images/check.svg" alt="" />
                        <div className="sub_paragraph_v2">
                            Совершайте покупки с картой из приложения Магнит или отправляйте фото чека в чат-бот Telegram в
                            течение 120 часов после совершения покупки и увеличивайте свои шансы на победу
                        </div>
                    </div>

                    <div className="check__container">
                        <img className="check__img" src="images/check.svg" alt="" />
                        <div className="sub_paragraph_v2">
                            Участвуйте в розыгрыше ценных призов каждую неделю и в розыгрыше главного приза конце акции
                        </div>
                    </div>

                    <p style={{ marginTop: 20 }} className="sub_paragraph_v2">Перечень призов, список товаров-спонсоров и подробные
                        условия по <a href="/" style={{ textDecoration: 'underline' }} className="red-text">ссылке</a>
                    </p>
                </div>
            </div>
        </div>


    )
}
export default IPhoneSE1
