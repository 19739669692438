import "./css/EndOfTheGame.css";
import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    useHistory,
    Link,
    withRouter
} from "react-router-dom";
import axios from 'axios';
import Geolocation from 'react-native-geolocation-service';
class EndOfTheGame extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        let latitude = localStorage.getItem('latitude');
        let longitude = localStorage.getItem('longitude');
        console.log(new Date())
        const userId = window.Telegram.WebApp.initDataUnsafe.user.id
        axios.post('https://demo.games.winsolutions.ru/WrightGeo?Id=' + userId + '&latitude=' + latitude + '&longitude=' + longitude)
    }
    componentDidMount() {
        alert("Поделитесь, пожалуйста, своей геолокацией, чтобы мы подобрали для вас верный приз.")
        Geolocation.getCurrentPosition(
            (position) => {
                localStorage.setItem('latitude', position.coords.latitude)
                localStorage.setItem('longitude', position.coords.longitude)
            }, (error) => {
                console.log(error.code, error.message);
            },
            { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 });
        console.log(new Date())
        setTimeout(() => {this.handleClick()},5000)
       
        
    }
    render() {
        return (
            <div className="div">
                <img className="ellipse-icon" alt="" src="ellipse-278.svg" />
                <img className="frame-icon" alt="" src="frame.svg" />
                <div className="div1" style={{ textAlign: "center" }}>Спасибо за участие!</div>
                <img className="frame-icon1" alt="" src="frame1.svg" />
                <img className="vector-icon" alt="" src="vector.svg" />
                <img className="vector-icon1" alt="" src="vector1.svg" />
                <img className="vector-icon2" alt="" src="vector2.svg" />
                <div className="div2" style={{ textAlign: "center" }}>
                    Мы пришлём результаты и информацию о Вашем выигрыше в течение 2 дней
                </div>
                <img className="vector-icon3" alt="" src="vector3.svg" />
                <img className="vector-icon4" alt="" src="vector4.svg" />
                <img className="vector-icon5" alt="" src="vector5.svg" />
            </div>
        );
    }
};
export default withRouter(EndOfTheGame)
