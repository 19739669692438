import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    useHistory,
    Link
} from "react-router-dom";
import './css/Terms.css'
import useWindowDimensions from '../hooks/useWindowDimensions';
const Terms = (props) => {
    const history = useHistory();
    const { height, width } = useWindowDimensions();
    const handleClick = async event => {
        history.push("/menu")
        console.log("back")

    }
    return (
        <div>
            <div className="frame1024">
                <div className="arrow_back_to_menu" style={{ alignSelf: 'flex-start' }}><a style={{ fontWeight: 900, fontSize: 29, color: 'white' }} onClick={handleClick}>
                    &#8592;</a></div>
                <div className='frame47915'>
                    <div className='frame1948754319'>
                        <span className='text'>
                            <span>Условия акции</span>
                        </span>
                        <div className='frame1948754317'>
                            <span className='text02'>
                                <span>
                                    Покупайте в наших магазинах с картой из приложения Магнит и
                                    получайте больше попыток в игре:
                                </span>
                            </span>
                            <div className='frame1948754311'>
                                <div className='frame1948754301'>
                                    <img
                                        src="/playground_assets/9e0f9d6e-31d8-4604-9a38-40af541badf2.svg"
                                        alt="Ellipse5011458"
                                        className='ellipse501'
                                    />
                                </div>
                                <div className='frame1948754309'>
                                    <span className='text04'>
                                        <span>На сумму от 500 ₽ = 1 попытка</span>
                                    </span>
                                    <span className='text06'>
                                        <span>
                                            в магазинах Магнит Косметик, Магнит у дома, Магнит Аптека
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className='frame19487543171'>
                                <div className='frame19487543011'>
                                    <img
                                        src="/playground_assets/9e0f9d6e-31d8-4604-9a38-40af541badf2.svg"
                                        alt="Ellipse501I1458"
                                        className='ellipse5011'
                                    />
                                </div>
                                <div className='frame19487543091'>
                                    <span className='text08'>
                                        <span>На сумму от 1000 ₽ = 1 попытка</span>
                                    </span>
                                    <span className='text10'>
                                        <span>
                                            в магазинах Магнит Семейный, Магнит Экстра, Магнит Опт
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className='frame1948754318'>
                                <div className='frame19487543012'>
                                    <img
                                        src="/playground_assets/9e0f9d6e-31d8-4604-9a38-40af541badf2.svg"
                                        alt="Ellipse501I1458"
                                        className='ellipse5012'
                                    />
                                </div>
                                <div className='frame19487543092'>
                                    <span className='text12'>
                                        <span>Товары-спонсоры</span>
                                    </span>
                                </div>
                            </div>
                            <div className='frame1948754316'>
                                <div className='frame1948754321'>
                                    <img
                                        src="/playground_assets/group19487543021461-w64a.svg"
                                        alt="Group19487543021461"
                                        className='group1948754302'
                                    />
                                    <span className='text14'>
                                        <span>
                                            В сумме не учитываются алкоголь, табачная и
                                            никотиносодержащая продукция, а также устройства для нее
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='frame1948754322'>
                        <span className='text16'>
                            <span>Купили без карты из приложения Магнит?</span>
                        </span>
                        <div className='frame1948754320'>
                            <img
                                src="/playground_assets/group19487543011459-y789.svg"
                                alt="Group19487543011459"
                                className='group1948754301'
                            />
                            <span className='text18'>
                                <span>
                                    <span>
                                        Забыли отсканировать карту? Не страшно! Отправьте фото чека
                                        в чат-бот Telegram в течение 120 часов после покупки.
                                    </span>
                                    <br></br>

                                </span>
                            </span>
                        </div>
                        <div className='frame19487543211'>
                            <img
                                src="/playground_assets/group19487543021459-k9r5.svg"
                                alt="Group19487543021459"
                                className='group19487543021'
                            />
                            <span className='text23'>
                                <span>
                                    Шансы в розыгрыше через чат-бот можно получить только за
                                    2 покупки в сутки из одного магазина
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Terms
