import React from 'react'
import { useState } from 'react';
import PropTypes, { element, func } from 'prop-types'
import axios from 'axios';
import './frame5.css'
import { render } from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    useHistory,
    Link
} from "react-router-dom";

const Frame5 = (props) => {
    var getValueFromLS = parseInt(localStorage.getItem('questionNumber'))
    var getCounter = parseInt(localStorage.getItem('counter'));
    if (getCounter == NaN) { getCounter = 15 }
    var [counter, setCounter] = React.useState(getCounter);
    if (getValueFromLS > 0) { }
    else { getValueFromLS = 1 }
    const [questionNumber, setQuestionNumber] = React.useState(parseInt(getValueFromLS));
    var [correctAnswer, setCorrectAnswer] = React.useState(parseInt(localStorage.getItem('correctAnswer')));
    var [correctAnswerText, setCorrectAnswerText] = React.useState(localStorage.getItem('correctAnswerText'));
    var [questionText, setQuestionText] = React.useState(localStorage.getItem('questionText'));
    var [questionAnswerOption1, setQuestionAnswerOption1] = React.useState(localStorage.getItem('questionAnswerOptionText1'));
    var [questionAnswerOption2, setQuestionAnswerOption2] = React.useState(localStorage.getItem('questionAnswerOptionText2'));
    var [questionAnswerOption3, setQuestionAnswerOption3] = React.useState(localStorage.getItem('questionAnswerOptionText3'));
    var [questionAnswerOption4, setQuestionAnswerOption4] = React.useState(localStorage.getItem('questionAnswerOptionText4'));
    console.log(questionText)
    if (questionText == null) {
        getAs(questionNumber);
    }
    console.log(questionText);
    console.log(questionNumber);
    const queryId = window.Telegram.WebApp.initDataUnsafe.query_id
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id
    window.Telegram.WebApp.expand();

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    let history = useHistory();
    if (localStorage.getItem('End') == 'true') {
        history.push('/EndOfTheGame')
    }
    async function getAs(qN) {
        if (qN <= 10) {
            var tmp = await axios.get('https://demo.games.winsolutions.ru/api/Questions?questionNumber=' + (qN))
            localStorage.setItem('questionAnswerOptionText1', tmp.data.questionAnswerOptions[0].questionAnswerOptionText);
            localStorage.setItem('questionAnswerOptionText2', tmp.data.questionAnswerOptions[1].questionAnswerOptionText);
            localStorage.setItem('questionAnswerOptionText3', tmp.data.questionAnswerOptions[2].questionAnswerOptionText);
            localStorage.setItem('questionAnswerOptionText4', tmp.data.questionAnswerOptions[3].questionAnswerOptionText);
            localStorage.setItem('questionText', tmp.data.questionText);
            localStorage.setItem('questionNumber', parseInt(questionNumber + 1));
            localStorage.setItem('counter', 15);
            var tmp1 = await axios.get('https://demo.games.winsolutions.ru/api/Questions?questionNumber=' + (qN))
            for (var i = 0; i < 4; i++) {
                if (tmp.data.questionAnswerOptions[i].questionAnswerOptionIsCorrectAnswer == true) {
                    localStorage.setItem('correctAnswer', i + 1)
                }
            }
            for (var i = 0; i < 4; i++) {
                if (tmp1.data.questionAnswerOptions[i].questionAnswerOptionIsCorrectAnswer == true) {
                    localStorage.setItem('correctAnswerText', tmp1.data.questionAnswerOptions[i].questionAnswerOptionText)

                }
            }
            setQuestionText(localStorage.getItem('questionText'))
            setQuestionAnswerOption1(localStorage.getItem('questionAnswerOptionText1'))
            setQuestionAnswerOption2(localStorage.getItem('questionAnswerOptionText2'))
            setQuestionAnswerOption3(localStorage.getItem('questionAnswerOptionText3'))
            setQuestionAnswerOption4(localStorage.getItem('questionAnswerOptionText4'))
            setCorrectAnswer(parseInt(localStorage.getItem('correctAnswer')))
            setCorrectAnswerText(localStorage.getItem('correctAnswerText'))
        }
        else if (qN == 11) {
            localStorage.setItem('End', true);
            localStorage.setItem('correctAnswer', 2);
            localStorage.setItem('correctAnswerText', 'В шоколадные конфеты ')
        }
    }
    async function timeIsUp() {
        getAs(questionNumber + 1);
        const checked = document.getElementsByClassName('frame5-vector1');
        for (var i = 0; i < checked.length; i++) {
            if (checked[i].style.visibility == 'visible') {
                console.log(correctAnswer)
                switch (checked[i].parentElement.parentElement.parentElement.className) {

                    case "frame5-frame50085":
                        axios.post('https://demo.games.winsolutions.ru/WrightAnswer?Id=' + (userId) + '&qa=' + (1) + '&qi=' + (questionNumber))
                        if (correctAnswer == 1) {
                            history.push("/RightAnswer");
                        }
                        else { history.push("/WrongAnswer"); }

                        break;
                    case "frame5-frame50086":
                        axios.post('https://demo.games.winsolutions.ru/WrightAnswer?Id=' + (userId) + '&qa=' + (2) + '&qi=' + (questionNumber))
                        if (correctAnswer == 2) {
                            history.push("/RightAnswer");
                        }
                        else { history.push("/WrongAnswer"); }

                        break;
                    case "frame5-frame50087":
                        axios.post('https://demo.games.winsolutions.ru/WrightAnswer?Id=' + (userId) + '&qa=' + (3) + '&qi=' + (questionNumber))
                        if (correctAnswer == 3) {
                            history.push("/RightAnswer");
                        }
                        else { history.push("/WrongAnswer"); }

                        break;
                    case "frame5-frame50088":
                        axios.post('https://demo.games.winsolutions.ru/WrightAnswer?Id=' + (userId) + '&qa=' + (4) + '&qi=' + (questionNumber))
                        if (correctAnswer == 4) {
                            history.push("/RightAnswer");
                        }
                        else { history.push("/WrongAnswer"); }

                        break;
                }
            }
        }
        if (window.location.pathname == "/")
            history.push("/WrongAnswer")
        screenReset();
    }

    React.useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        localStorage.setItem('counter', counter - 1)
        if (counter == 0)
            timeIsUp()
        return () => clearInterval(timer);
    }, [counter]);
    const screenReset = () => {
        var option1 = document.getElementsByClassName('frame5-frame50085');
        var option2 = document.getElementsByClassName('frame5-frame50086');
        var option3 = document.getElementsByClassName('frame5-frame50087');
        var option4 = document.getElementsByClassName('frame5-frame50088');
        var element1 = option1[0].getElementsByClassName('frame5-vector1');
        var element2 = option2[0].getElementsByClassName('frame5-vector1');
        var element3 = option3[0].getElementsByClassName('frame5-vector1');
        var element4 = option4[0].getElementsByClassName('frame5-vector1');
        option1[0].style.backgroundColor = 'rgba(255, 255, 255, 1)';
        option2[0].style.backgroundColor = 'rgba(255, 255, 255, 1)';
        option3[0].style.backgroundColor = 'rgba(255, 255, 255, 1)';
        option4[0].style.backgroundColor = 'rgba(255, 255, 255, 1)';
        option1[0].querySelector('img').src = "/playground_assets/rectangle4192i762-oc4-200h.png";
        option2[0].querySelector('img').src = "/playground_assets/rectangle4192i762-oc4-200h.png";
        option3[0].querySelector('img').src = "/playground_assets/rectangle4192i762-oc4-200h.png";
        option4[0].querySelector('img').src = "/playground_assets/rectangle4192i762-oc4-200h.png";
        element1[0].style.visibility = 'hidden';
        element2[0].style.visibility = 'hidden';
        element3[0].style.visibility = 'hidden';
        element4[0].style.visibility = 'hidden';
    }
    const handleClick = async event => {
        screenReset()
        var clickedElementCheckVector = event.currentTarget.getElementsByClassName('frame5-vector1');
        event.currentTarget.style.backgroundColor = 'rgba(255, 215, 135, 1)';
        event.currentTarget.querySelector('img').src = "/playground_assets/rectangle4192i762-j6f-200h.png"
        clickedElementCheckVector[0].style.visibility = 'visible';

    }
    return (
        <div className="frame5-frame5">

            <img className="frame-icon1_2" alt="" src="../frame.svg" />
            <div className="frame5-frame">
                <div className="frame5-group1948754297">
                    <img
                        src={props.Vector_src2}
                        alt={props.Vector_alt2}
                        className="frame5-vector27"
                    />
                    <img
                        src={props.Vector_src3}
                        alt={props.Vector_alt3}
                        className="frame5-vector28"
                    />
                    <img
                        src={props.Ellipse274_src}
                        alt={props.Ellipse274_alt}
                        className="frame5-ellipse274"
                    />
                    <img
                        src={props.Vector388_src}
                        alt={props.Vector388_alt}
                        className="frame5-vector388"
                    />
                    <img
                        src={props.Vector389_src}
                        alt={props.Vector389_alt}
                        className="frame5-vector389"
                    />
                </div>
                <div className="answCont">
               
                <div className="frame5-text02">
                    <span className="frame5-text03">
                        Вопрос
                        <span
                            dangerouslySetInnerHTML={{
                                __html: ' ',
                            }}
                        />
                    </span>
                    <span>{questionNumber} из 10<br></br></span>
                </div>
                    <div className="frame5-text">
                        <span>
                            {questionText}
                        </span>
                    </div>
                <div className="frame5-frame50085" onClick={handleClick} style={{
                    fontSize: 'auto', cursor: 'pointer'
                }}>
                    <div className="frame5-frame50084">
                        <div className="frame5-frame1">
                            <img
                                src={props.Rectangle4192_src}
                                alt={props.Rectangle4192_alt}
                                className="frame5-rectangle4192"
                            />
                            <img
                                src={props.Vector1_src}
                                alt={props.Vector1_alt}
                                className="frame5-vector1"
                                style={{ visibility: 'hidden' }}
                            />
                        </div>
                        <span className="frame5-text05">
                            <span>{questionAnswerOption1}</span>
                        </span>
                    </div>
                </div>
                <div className="frame5-frame50086" onClick={handleClick} style={{
                    cursor: 'pointer'
                }}>
                    <div className="frame5-frame500841">
                        <div className="frame5-frame11">
                            <img
                                src={props.Rectangle4192_src2}
                                alt={props.Rectangle4192_alt}
                                className="frame5-rectangle41921"
                            />
                            <img
                                src={props.Vector1_src}
                                alt={props.Vector1_alt}
                                className="frame5-vector1"
                                style={{ visibility: 'hidden' }}
                            />
                        </div>
                        <span className="frame5-text07">
                            <span>{questionAnswerOption2}</span>
                        </span>
                    </div>
                </div>
                <div className="frame5-frame50087" onClick={handleClick} style={{
                    cursor: 'pointer'
                }}>
                    <div className="frame5-frame500842">
                        <div className="frame5-frame12">
                            <img
                                src={props.Rectangle4192_src2}
                                alt={props.Rectangle4192_alt2}
                                className="frame5-rectangle41922"
                            />
                            <img
                                src={props.Vector1_src}
                                alt={props.Vector1_alt}
                                className="frame5-vector1"
                                style={{ visibility: 'hidden' }}
                            />
                        </div>
                        <span className="frame5-text09">
                            <span>{questionAnswerOption3}</span>
                        </span>
                    </div>
                </div>
                <div className="frame5-frame50088" onClick={handleClick} style={{
                    cursor: 'pointer'
                }}>
                    <div className="frame5-frame500843">
                        <div className="frame5-frame13">
                            <img
                                src={props.Rectangle4192_src3}
                                alt={props.Rectangle4192_alt3}
                                className="frame5-rectangle41923"
                            />
                            <img
                                src={props.Vector1_src}
                                alt={props.Vector1_alt}
                                style={{ visibility: 'hidden' }}
                                className="frame5-vector1"
                            />
                        </div>
                        <span className="frame5-text11">
                            <span>{questionAnswerOption4}</span>
                        </span>
                    </div>
                    </div>
                    </div>
                <span className="frame5-text13">
                    <span>{counter >= '10' ? <span>0:{counter}</span> : <span>0:0{counter}</span>}</span>
                </span>
                <span className="frame5-text15">
                    <span>
                        <span>Осталось</span>
                        <br></br>
                        <span>времени</span>
                    </span>
                </span>
                <img
                    src={props.Vector_src}
                    alt={props.Vector_alt}
                    className="frame5-vector29"
                />
                <img
                    src={props.Vector_src1}
                    alt={props.Vector_alt1}
                    className="frame5-vector30"
                />
            </div>
        </div>
    )
}

Frame5.defaultProps = {
    Vector_src: '/playground_assets/vector1833-6g8.svg',
    Vector_alt: 'Vector1833',
    Vector_src1: '/playground_assets/vector1833-rox9.svg',
    Vector_alt1: 'Vector1833',
    Vector_src2: '/playground_assets/vector7724-leb9.svg',
    Vector_alt2: 'Vector7724',
    Vector_src3: '/playground_assets/vector7725-63tj.svg',
    Vector_alt3: 'Vector7725',
    Ellipse274_src: '/playground_assets/ellipse2747725-r95.svg',
    Ellipse274_alt: 'Ellipse2747725',
    Vector388_src: '/playground_assets/vector3887725-l2a.svg',
    Vector388_alt: 'Vector3887725',
    Vector389_src: '/playground_assets/vector3897725-7cf9.svg',
    Vector389_alt: 'Vector3897725',
    Vector_src4: '/playground_assets/vector7650-9v2.svg',
    Vector_alt4: 'Vector7650',
    Vector_src5: '/playground_assets/vector7650-rzld.svg',
    Vector_alt5: 'Vector7650',
    Vector_src6: '/playground_assets/vector7650-9gr.svg',
    Vector_alt6: 'Vector7650',
    Vector_src7: '/playground_assets/vector7651-or7n.svg',
    Vector_alt7: 'Vector7651',
    Vector_src8: '/playground_assets/vector7651-49j.svg',
    Vector_alt8: 'Vector7651',
    Vector_src9: '/playground_assets/vector7651-evtt.svg',
    Vector_alt9: 'Vector7651',
    Vector_src10: '/playground_assets/vector7651-0r97.svg',
    Vector_alt10: 'Vector7651',
    Vector_src11: '/playground_assets/vector7651-extj.svg',
    Vector_alt11: 'Vector7651',
    Vector_src12: '/playground_assets/vector7651-1haa.svg',
    Vector_alt12: 'Vector7651',
    Vector_src13: '/playground_assets/vector7651-b2pn.svg',
    Vector_alt13: 'Vector7651',
    Vector_src14: '/playground_assets/vector7651-krg.svg',
    Vector_alt14: 'Vector7651',
    Vector_src15: '/playground_assets/vector7651-xsli.svg',
    Vector_alt15: 'Vector7651',
    Vector_src16: '/playground_assets/vector7651-a7vh.svg',
    Vector_alt16: 'Vector7651',
    Vector_src17: '/playground_assets/vector7652-yf8u.svg',
    Vector_alt17: 'Vector7652',
    Vector_src18: '/playground_assets/vector7652-6d4f.svg',
    Vector_alt18: 'Vector7652',
    Vector_src19: '/playground_assets/vector7652-qhd.svg',
    Vector_alt19: 'Vector7652',
    Vector_src20: '/playground_assets/vector7652-wpf.svg',
    Vector_alt20: 'Vector7652',
    Vector_src21: '/playground_assets/vector7652-vpa5.svg',
    Vector_alt21: 'Vector7652',
    Vector_src22: '/playground_assets/vector7652-bxiy3.svg',
    Vector_alt22: 'Vector7652',
    Vector_src23: '/playground_assets/vector7652-4vmk.svg',
    Vector_alt23: 'Vector7652',
    Vector_src24: '/playground_assets/vector7652-2al.svg',
    Vector_alt24: 'Vector7652',
    Vector_src25: '/playground_assets/vector7652-8t8v.svg',
    Vector_alt25: 'Vector7652',
    Vector_src26: '/playground_assets/vector7652-5o.svg',
    Vector_alt26: 'Vector7652',
    Vector_src27: '/playground_assets/vector7653-ybm9.svg',
    Vector_alt27: 'Vector7653',
    Vector_src28: '/playground_assets/vector7653-rzhd.svg',
    Vector_alt28: 'Vector7653',
    Vector_src29: '/playground_assets/vector7653-aix.svg',
    Vector_alt29: 'Vector7653',
    Vector_src30: '/playground_assets/vector7653-3cd.svg',
    Vector_alt30: 'Vector7653',
    Rectangle4192_src: '/playground_assets/rectangle4192i762-xhkr-200h.png',
    Rectangle4192_alt: 'Rectangle4192I762',
    Rectangle4192_src1: '/playground_assets/rectangle4192i762-j6f-200h.png',
    Rectangle4192_alt1: 'Rectangle4192I762',
    Vector1_src: '/playground_assets/vector-1.svg',
    Vector1_alt: 'Vector1I762',
    Rectangle4192_src2: '/playground_assets/rectangle4192i762-oc4-200h.png',
    Rectangle4192_alt2: 'Rectangle4192I762',
    Rectangle4192_src3: '/playground_assets/rectangle4192i762-p2qm-200h.png',
    Rectangle4192_alt3: 'Rectangle4192I762',
}

Frame5.propTypes = {
    Vector_src: PropTypes.string,
    Vector_alt: PropTypes.string,
    Vector_src1: PropTypes.string,
    Vector_alt1: PropTypes.string,
    Vector_src2: PropTypes.string,
    Vector_alt2: PropTypes.string,
    Vector_src3: PropTypes.string,
    Vector_alt3: PropTypes.string,
    Ellipse274_src: PropTypes.string,
    Ellipse274_alt: PropTypes.string,
    Vector388_src: PropTypes.string,
    Vector388_alt: PropTypes.string,
    Vector389_src: PropTypes.string,
    Vector389_alt: PropTypes.string,
    Vector_src4: PropTypes.string,
    Vector_alt4: PropTypes.string,
    Vector_src5: PropTypes.string,
    Vector_alt5: PropTypes.string,
    Vector_src6: PropTypes.string,
    Vector_alt6: PropTypes.string,
    Vector_src7: PropTypes.string,
    Vector_alt7: PropTypes.string,
    Vector_src8: PropTypes.string,
    Vector_alt8: PropTypes.string,
    Vector_src9: PropTypes.string,
    Vector_alt9: PropTypes.string,
    Vector_src10: PropTypes.string,
    Vector_alt10: PropTypes.string,
    Vector_src11: PropTypes.string,
    Vector_alt11: PropTypes.string,
    Vector_src12: PropTypes.string,
    Vector_alt12: PropTypes.string,
    Vector_src13: PropTypes.string,
    Vector_alt13: PropTypes.string,
    Vector_src14: PropTypes.string,
    Vector_alt14: PropTypes.string,
    Vector_src15: PropTypes.string,
    Vector_alt15: PropTypes.string,
    Vector_src16: PropTypes.string,
    Vector_alt16: PropTypes.string,
    Vector_src17: PropTypes.string,
    Vector_alt17: PropTypes.string,
    Vector_src18: PropTypes.string,
    Vector_alt18: PropTypes.string,
    Vector_src19: PropTypes.string,
    Vector_alt19: PropTypes.string,
    Vector_src20: PropTypes.string,
    Vector_alt20: PropTypes.string,
    Vector_src21: PropTypes.string,
    Vector_alt21: PropTypes.string,
    Vector_src22: PropTypes.string,
    Vector_alt22: PropTypes.string,
    Vector_src23: PropTypes.string,
    Vector_alt23: PropTypes.string,
    Vector_src24: PropTypes.string,
    Vector_alt24: PropTypes.string,
    Vector_src25: PropTypes.string,
    Vector_alt25: PropTypes.string,
    Vector_src26: PropTypes.string,
    Vector_alt26: PropTypes.string,
    Vector_src27: PropTypes.string,
    Vector_alt27: PropTypes.string,
    Vector_src28: PropTypes.string,
    Vector_alt28: PropTypes.string,
    Vector_src29: PropTypes.string,
    Vector_alt29: PropTypes.string,
    Vector_src30: PropTypes.string,
    Vector_alt30: PropTypes.string,
    Rectangle4192_src: PropTypes.string,
    Rectangle4192_alt: PropTypes.string,
    Rectangle4192_src1: PropTypes.string,
    Rectangle4192_alt1: PropTypes.string,
    Vector1_src: PropTypes.string,
    Vector1_alt: PropTypes.string,
    Rectangle4192_src2: PropTypes.string,
    Rectangle4192_alt2: PropTypes.string,
    Rectangle4192_src3: PropTypes.string,
    Rectangle4192_alt3: PropTypes.string,
}

export default Frame5